import routes from '@config/routes';
import instance from '@instance';
import type { UserAgencyDetails } from '@v2/types';
import {
  AlertToast,
  AlertTriangle,
  Button,
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
  Input,
  Label,
  ScrollArea,
} from '@v2/ui';
import { ChevronDown } from '@v2/ui/Icon/ChevronDown/ChevronDown';
import {
  countriesOptions,
  statusOptions,
} from '@v2/views/partners/partnersSIgnup/data';
import { useFormik } from 'formik';
import { useSession } from 'next-auth/react';
import { useState } from 'react';
import toast from 'react-hot-toast';
import * as yup from 'yup';

const formSchema = yup.object().shape({
  businessName: yup.string().required('Required'),
  country: yup.string().required('Required'),
  streetAddress: yup.string().required('Required'),
  city: yup.string().required('Required'),
  zipCode: yup.string().required('Required'),
  affiliationId: yup.string(),
});

export const AfilliateDetailsForm = (
  props: UserAgencyDetails & { onClose: () => void }
) => {
  const session = useSession();

  const [isLoading, setLoading] = useState(false);
  const {
    twitter,
    instagram,
    tiktok,
    businessName,
    country,
    streetAddress,
    zipCode,
    website,
    city,
    youtube,
    onClose,
    commissionRate,
    status,
    id,
    ticketCommissionRate,
    hotelCommissionRate,
    affiliationId,
  } = props;

  const initialaffiliationId = affiliationId;
  const {
    handleSubmit,
    handleBlur,
    handleChange,
    values,
    errors,
    touched,
    setFieldValue,
    isValid,
  } = useFormik({
    onSubmit: async ({ affiliationId, ...values }) => {
      const currentValues = { ...values };
      if (affiliationId !== initialaffiliationId) {
        currentValues.affiliationId = affiliationId;
      }
      // @ts-ignore
      const token = session.data?.user.token.accessToken;

      try {
        setLoading(true);
        const bodyValues = { ...currentValues };

        const response = await instance.server(`${routes.agencyAdmin}/${id}`, {
          body: JSON.stringify({
            ...bodyValues,
          }),
          method: 'PATCH',
          headers: { Authorization: `Bearer ${token}` },
        });
        if (response.ok) {
          window.location.reload();
          onClose();
        } else {
          toast.custom(
            (t) => (
              <AlertToast
                {...t}
                title="Oops, something went wrong"
                content={'Please try again in a minute.'}
                icon={<AlertTriangle />}
              />
            ),
            { position: 'top-right' }
          );
        }
      } catch (e: any) {
        console.log(e);
      } finally {
        setLoading(false);
      }
    },

    initialValues: {
      affiliationId,
      businessName,
      country,
      streetAddress,
      city,
      zipCode,
      website,
      youtube,
      instagram,
      twitter,
      tiktok,
      commissionRate,
      status,
      ticketCommissionRate,
      hotelCommissionRate,
    },
    enableReinitialize: true,
    validationSchema: formSchema,
  });

  return (
    <div>
      <form className="flex w-full flex-col gap-y-5" onSubmit={handleSubmit}>
        <div className="flex flex-col items-start gap-4 md:flex-row">
          <div className="grid w-full items-center gap-1.5">
            <Label htmlFor="affiliationId" isRequired>
              Affiliation ID
            </Label>
            <Input
              extent="md"
              type="text"
              name="affiliationId"
              id="affiliationId"
              required
              value={values.affiliationId}
              onChange={handleChange}
              onBlur={handleBlur}
              isError={
                errors.affiliationId !== undefined && touched.affiliationId
              }
              errorText={errors.affiliationId}
            />
          </div>
          <div className="grid w-full items-center gap-1.5">
            <Label htmlFor="businessName" isRequired>
              Business name
            </Label>
            <Input
              extent="md"
              type="text"
              name="businessName"
              id="businessName"
              required
              value={values.businessName}
              onChange={handleChange}
              onBlur={handleBlur}
              isError={
                errors.businessName !== undefined && touched.businessName
              }
              errorText={errors.businessName}
            />
          </div>
          <div className="grid w-full items-center gap-1.5">
            <Label htmlFor="country" isRequired>
              Country
            </Label>
            <DropdownMenu>
              <DropdownMenuTrigger asChild>
                <Input
                  name="country"
                  id="country"
                  onBlur={handleBlur}
                  isError={errors.country !== undefined && touched.country}
                  errorText={errors.country}
                  className="text-start"
                  placeholder="Select"
                  value={values.country}
                  readOnly
                  endIcon={
                    errors.country !== undefined && touched.country ? null : (
                      <ChevronDown size="20" className="text-gray-500" />
                    )
                  }
                  extent={'md'}
                />
              </DropdownMenuTrigger>
              <DropdownMenuContent align="start" className="min-w-[560px]">
                <ScrollArea className="h-[300px]">
                  {countriesOptions.map((item) => (
                    <DropdownMenuItem
                      key={item.value}
                      onSelect={() => setFieldValue('country', item.label)}
                    >
                      {item.label}
                    </DropdownMenuItem>
                  ))}
                </ScrollArea>
              </DropdownMenuContent>
            </DropdownMenu>
          </div>
        </div>

        <div className="flex flex-col items-start gap-4 md:flex-row">
          <div className="grid w-full items-center gap-1.5">
            <Label htmlFor="ticketCommissionRate" isRequired>
              Ticket comission rate
            </Label>
            <Input
              extent="md"
              type="number"
              name="ticketCommissionRate"
              id="ticketCommissionRate"
              required
              value={values.ticketCommissionRate}
              onChange={handleChange}
              onBlur={handleBlur}
              isError={
                errors.ticketCommissionRate !== undefined &&
                touched.ticketCommissionRate
              }
              errorText={errors.ticketCommissionRate}
            />
          </div>

          <div className="grid w-full items-center gap-1.5">
            <Label htmlFor="hotelCommissionRate" isRequired>
              Hotel comission rate
            </Label>
            <Input
              extent="md"
              type="number"
              name="hotelCommissionRate"
              id="hotelCommissionRate"
              required
              value={values.hotelCommissionRate}
              onChange={handleChange}
              onBlur={handleBlur}
              isError={
                errors.hotelCommissionRate !== undefined &&
                touched.hotelCommissionRate
              }
              errorText={errors.hotelCommissionRate}
            />
          </div>
          <div className="grid w-full items-center gap-1.5">
            <Label htmlFor="status" isRequired>
              Status
            </Label>
            <DropdownMenu>
              <DropdownMenuTrigger asChild>
                <Input
                  name="status"
                  id="status"
                  onBlur={handleBlur}
                  isError={errors.status !== undefined && touched.status}
                  errorText={errors.status}
                  className="text-start"
                  placeholder="Select"
                  value={
                    statusOptions.find(
                      (status) => status.value === values.status
                    )?.label ?? ''
                  }
                  readOnly
                  endIcon={
                    errors.status !== undefined && touched.status ? null : (
                      <ChevronDown size="20" className="text-gray-500" />
                    )
                  }
                  extent={'md'}
                />
              </DropdownMenuTrigger>
              <DropdownMenuContent align="start" className="min-w-[560px]">
                {statusOptions.map((item) => (
                  <DropdownMenuItem
                    key={item.value}
                    onSelect={() => setFieldValue('status', item.value)}
                  >
                    {item.label}
                  </DropdownMenuItem>
                ))}
              </DropdownMenuContent>
            </DropdownMenu>
          </div>
        </div>

        <div className="grid w-full items-center gap-1.5">
          <Label htmlFor="streetAddress" isRequired>
            Street address{' '}
          </Label>
          <Input
            extent="md"
            type="text"
            name="streetAddress"
            id="streetAddress"
            required
            value={values.streetAddress}
            onChange={handleChange}
            onBlur={handleBlur}
            isError={
              errors.streetAddress !== undefined && touched.streetAddress
            }
            errorText={errors.streetAddress}
          />
        </div>

        <div className="flex flex-col items-start gap-4 md:flex-row">
          <div className="grid w-full items-center gap-1.5">
            <Label htmlFor="city" isRequired>
              City
            </Label>
            <Input
              extent="md"
              type="text"
              name="city"
              id="city"
              required
              value={values.city}
              onChange={handleChange}
              onBlur={handleBlur}
              isError={errors.city !== undefined && touched.city}
              errorText={errors.city}
            />
          </div>
          <div className="grid w-full items-center gap-1.5">
            <Label htmlFor="zipCode" isRequired>
              ZIP/postal code
            </Label>
            <Input
              extent="md"
              type="text"
              name="zipCode"
              id="zipCode"
              required
              value={values.zipCode}
              onChange={handleChange}
              onBlur={handleBlur}
              isError={errors.zipCode !== undefined && touched.zipCode}
              errorText={errors.zipCode}
            />
          </div>
        </div>
        <div className="flex flex-col items-start gap-4 md:flex-row">
          <div className="grid w-full items-center gap-1.5">
            <Label htmlFor="website">Website</Label>
            <Input
              extent="md"
              type="text"
              name="website"
              id="website"
              value={values.website}
              onChange={handleChange}
              onBlur={handleBlur}
              isError={errors.website !== undefined && touched.website}
              errorText={errors.website}
            />
          </div>
        </div>

        <div className="flex flex-col items-start gap-4 md:flex-row">
          <div className="grid w-full items-center gap-1.5">
            <Label htmlFor="youtube">Youtube</Label>
            <Input
              extent="md"
              type="text"
              name="youtube"
              id="youtube"
              value={values.youtube}
              onChange={handleChange}
              onBlur={handleBlur}
              isError={errors.youtube !== undefined && touched.youtube}
              errorText={errors.youtube}
            />
          </div>
          <div className="grid w-full items-center gap-1.5">
            <Label htmlFor="instagram">Instagram</Label>
            <Input
              extent="md"
              type="text"
              name="instagram"
              id="instagram"
              value={values.instagram}
              onChange={handleChange}
              onBlur={handleBlur}
              isError={errors.instagram !== undefined && touched.instagram}
              errorText={errors.instagram}
            />
          </div>
          <div className="grid w-full items-center gap-1.5">
            <Label htmlFor="twitter">Twitter</Label>
            <Input
              extent="md"
              type="text"
              name="twitter"
              id="twitter"
              value={values.twitter}
              onChange={handleChange}
              onBlur={handleBlur}
              isError={errors.twitter !== undefined && touched.twitter}
              errorText={errors.twitter}
            />
          </div>
          <div className="grid w-full items-center gap-1.5">
            <Label htmlFor="tiktok">Tiktok</Label>
            <Input
              extent="md"
              type="text"
              name="tiktok"
              id="tiktok"
              value={values.tiktok}
              onChange={handleChange}
              onBlur={handleBlur}
              isError={errors.tiktok !== undefined && touched.tiktok}
              errorText={errors.tiktok}
            />
          </div>
        </div>
        <Button
          className="max-w-36"
          variant="primary"
          disabled={!isValid}
          type="submit"
          isLoading={isLoading}
        >
          Save changes
        </Button>
      </form>
    </div>
  );
};
